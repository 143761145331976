/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query frankfurt {
    file(relativePath: { eq: "parking-lotnisko-frankfurt-front.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parkingi Lotnisko Frankfurt nad Menem Niemcy",
  tagline: "Frankfurt nad Menem, Niemcy",
  featuredImage: "/assets/parking-lotnisko-frankfurt-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}
const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <b>Adres:</b> 60547 Frankfurt, Niemcy Kod: FRA
      </p>
    }
  >
    <strong>Parkingi przy lotnisku Frankfurt nad Menem, Niemcy</strong>
    <h3 id="lotnisko">Lotnisko</h3>
    <p>
      Frankfurt Airport, znany również jako Flughafen Frankfurt Main, jest
      głównym międzynarodowym lotniskiem w Niemczech i oferuje szeroką gamę
      opcji parkingowych dla podróżnych. Znajduje się w strategicznym miejscu w
      regionie Frankfurt/Rhine-Main, jednym z najgęściej zaludnionych regionów w
      Europie.
    </p>
    <h2 id="lokalizacja">Lokalizacja</h2>
    <p>
      Lotnisko Frankfurt leży 12 km na południowy zachód od centrum Frankfurtu,
      w pobliżu skrzyżowania autostrad A3 i A5. Jest otoczone przez Las Miejski
      we Frankfurcie, co sprawia, że jest łatwo dostępne zarówno samochodem, jak
      i rowerem.
    </p>
    <h2 id="opcje-parkowania">Opcje parkowania</h2>
    <h3 id="parking-wielopoziomowy">Parking wielopoziomowy</h3>
    <p>
      Dla pasażerów, którzy przyjeżdżają własnym samochodem, lotnisko oferuje
      parkingi wielopoziomowe (głównie podziemne) wzdłuż terminali. Są one
      idealne dla osób, które potrzebują krótkoterminowego parkowania w bliskiej
      odległości od terminali.
    </p>
    <h3 id="parking-długoterminowy">Parking długoterminowy</h3>
    <p>
      Dla tych, którzy planują dłuższy pobyt, istnieje parking długoterminowy
      położony na południe od pasów startowych. Jest on połączony z terminalami
      autobusem wahadłowym, co zapewnia wygodny dostęp dla podróżnych.
    </p>
    <h3 id="parking-dla-rowerów">Parking dla rowerów</h3>
    <p>
      Dla ekologicznie świadomych podróżnych, zarówno Terminal 1, jak i Terminal
      2 są dostępne rowerem. Władze lotniska potwierdziły, że nowo wybudowany
      terminal 3 będzie również dostępny dla rowerzystów.
    </p>
    <h3 id="autobusy-i-autokary">Autobusy i autokary</h3>
    <p>
      Różne firmy transportowe oferują usługi autobusowe do lotniska z
      okolicznych obszarów, a także autokary do dalszych miejsc docelowych.
    </p>
    <h2 id="dlaczego-warto-wybrać-parking-przy-lotnisku-frankfurt">
      Dlaczego warto wybrać parking przy lotnisku Frankfurt?
    </h2>
    <ul>
      <li>
        <strong>Lokalizacja</strong>: Parkingi są zlokalizowane w bliskiej
        odległości od terminali, co zapewnia szybki dostęp do lotniska.
      </li>
      <li>
        <strong>Bezpieczeństwo</strong>: Tereny parkingowe są monitorowane 24/7,
        co gwarantuje bezpieczeństwo Twojego samochodu podczas Twojej
        nieobecności.
      </li>
      <li>
        <strong>Elastyczność</strong>: Możliwość rezerwacji miejsca parkingowego
        online, co pozwala na zaplanowanie podróży i uniknięcie stresu
        związanego z poszukiwaniem wolnego miejsca na ostatnią chwilę.
      </li>
    </ul>

    <h2 id="jak-zarezerwować-miejsce-parkingowe">
      Jak zarezerwować miejsce parkingowe?
    </h2>
    <ol>
      <li>Wejdź na naszą stronę internetową.</li>
      <li>Wybierz preferowany parking z listy dostępnych opcji.</li>
      <li>Wprowadź datę i godzinę przyjazdu oraz wyjazdu.</li>
      <li>Dokonaj płatności online.</li>
      <li>Otrzymasz potwierdzenie rezerwacji na swój adres e-mail.</li>
    </ol>
    <p>
      Nie czekaj, zarezerwuj swoje miejsce parkingowe już teraz i ciesz się
      spokojem podczas podróży z lotniska we Frankfurcie!
    </p>
    <h2 id="statystyki-dotyczące-transportu-naziemnego">
      Statystyki dotyczące transportu naziemnego
    </h2>
    <p>
      W 2006 roku 29,5% z 12 299 192 pasażerów, których podróż lotnicza
      rozpoczęła się we Frankfurcie, przybyło prywatnym samochodem, 27,9%
      koleją, 20,4% taksówką, 11,1% zaparkowało swój samochód na lotnisku na
      czas swojej podróży, 5,3% przybyło autobusem, a 4,6% przybyło samochodem
      wynajętym.
    </p>
    <p>
      <strong>Frankfurt Airport - Q&amp;A</strong>
    </p>
    <p>
      <strong>1. Gdzie znajduje się lotnisko we Frankfurcie?</strong>
      A: Lotnisko we Frankfurcie znajduje się 12 km na południowy zachód od
      centrum Frankfurtu.
    </p>
    <p>
      <strong>2. Kiedy otwarto Terminal 1 na lotnisku we Frankfurcie?</strong>
      A: Terminal 1 na lotnisku we Frankfurcie został otwarty dla publiczności
      14 marca 1972 roku.
    </p>
    <p>
      <strong>3. Kiedy otwarto Terminal 2 na lotnisku we Frankfurcie?</strong>
      A: Terminal 2 na lotnisku we Frankfurcie został otwarty w 1994 roku.
    </p>
    <p>
      <strong>4. Jakie są główne cechy Terminalu 1?</strong>
      A: Terminal 1 jest podzielony na hale A, B, C i Z i ma pojemność około 50
      milionów pasażerów rocznie.
    </p>
    <p>
      <strong>5. Jakie są główne cechy Terminalu 2?</strong>
      A: Terminal 2 ma pojemność 15 milionów pasażerów rocznie i jest podzielony
      na hale D i E.
    </p>
    <h2 id="podsumowanie">Podsumowanie</h2>
    <p>
      Frankfurt Airport oferuje szeroką gamę opcji parkingowych, które spełniają
      potrzeby różnych podróżnych. Bez względu na to, czy potrzebujesz
      krótkoterminowego parkowania w pobliżu terminali, czy długoterminowego
      parkowania podczas dłuższej podróży, lotnisko Frankfurt ma coś dla
      każdego.
    </p>
    <p>
      Jeśli planujesz podróż przez Frankfurt Airport i potrzebujesz informacji o
      parkowaniu, skorzystaj z oferowanych przez lotnisko opcji, aby uczynić
      swoją podróż jak najbardziej komfortową.
    </p>
  </Homepage>
)

export default HomePage
